.icon {
    border-bottom: 3px solid transparent;
}

.icon .active {
    border-bottom: 3px solid #00688B;
    color: #00688b;
}

.search-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* flex-direction: column; */
}

.ais-SearchBox {
    width: 50%;
    height: 35px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1100px) {
        width: 65%;
    }

    @media screen and (max-width: 1000px) {
        width: 70%;
    }

    @media screen and (max-width: 769px) {
        width: 90%;
        height: 40px;
    }

    @media screen and (max-width: 480px) {
        height: 35px;
    }
}

.ais-SearchBox-form {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.ais-SearchBox-input {
    width: 93%;
    padding: 0 10px;
    border-radius: 8px 0 0 8px;
    border: 1px solid gray;

    &:focus {
        outline: none;
    }

    @media screen and (max-width: 850px) {
        width: 90%;
    }

    @media screen and (max-width: 769px) {
        width: 93%;
    }

    @media screen and (max-width: 480px) {
        width: 90%;
    }
}

.ais-SearchBox-submit {
    width: 7%;
    cursor: pointer;
    background-color: #197fa6;
    border-radius: 0 8px 8px 0;
    border: none;

    @media screen and (max-width: 900px) {
        width: 10%;
    }

    @media screen and (max-width: 769px) {
        width: 7%;
    }

    @media screen and (max-width: 480px) {
        width: 10%;
    }
}

.ais-SearchBox-reset {
    display: none;
}

.ais-Hits {
    position: absolute;
    top: 45px;
    width: 50%;
    z-index: 10;

    @media screen and (max-width: 1100px) {
        width: 65%;
    }

    @media screen and (max-width: 1000px) {
        width: 70%;
    }

    @media screen and (max-width: 769px) {
        width: 90%;
    }

}

.ais-Hits-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    background-color: #EEF5F7;
}

.ais-Hits-item  {
    display: flex;
    cursor: pointer;
    padding: 8px 0;

    &:hover{
        background-color: #DEE3E5;
        border-radius: 8px;
    }

    .hit {
        display: flex;
        gap: 8px;
        padding: 0 10px;
    }

    .title {
        height: 41px;
        line-height: 20px;
        overflow: hidden;
        color:  #002734;
        text-overflow: ellipsis;
        whitespace: nowrap;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
    }

    .title .highlighted {
        font-style: normal;
        color: blue;
        /* background-color: #E8EEFF; */
    }
}

