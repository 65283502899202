.bottomSheet.open {
  transform: translateY(0);
  transition: transform 0.3s ease-out;
}

.feedback-bottomSheet {
  position: fixed;
  overflow: hidden;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f9f9;
  border-radius: 30px 30px 0 0;
  transition: transform 0.3s ease-out;
  transform: translateY(100%);
  z-index: 1600;
}

.feedback-bottomSheet.open {
  transform: translateY(0);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  transition: transform 0.3s ease-out;
  transform: translateY(-100%);
  z-index: 5;
}

.overlay.open {
    transform: translateY(0);
}
