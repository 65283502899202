.wrapper {
    display: flex;
    justify-content: space-between;
    /* align-items: center;
    justify-content: center; */
    margin: 40px 0;
  }
  /* for step name */
  .stepItem {
      
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    
    /* background: lightblue; */
  }
  
  /* for step bar */
  .stepItem:not(:first-child):before {
    
    content: " ";
    position: absolute;
    width: 100%;
    height: 3px;
    top: 12px;
    right: 50%;
    
    /* transform: translateY(-2400%); */
    background-color: #D52B2B;
  }
  /* for circle */
  .Step {
    width: 1.7rem;
    height: 1.7rem;
    background-color: #D52B2B;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    border-radius: 50%;
    color: #fff;
  }
  /* for step active */
  .Active .Step {
      background: #D52B2B;
      }
  /* for step complete */
  .Complete .Step {
      background: #D52B2B;
  }
   span{
      color: #667D85;
      font-weight: 600;
      font-size: 15px;
  }
  .Active span{
  color:#D52B2B;
  }
  .Complete:not(:first-child):before,
  .Active:not(:first-child):before{
      background:#D52B2B;
  }
  
  /* mes tooltip */
  .tooltip {
  padding:.5rem;
  width: max-content;
  border-radius: .3rem;
  background: #fff;
  font-size: 12px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  line-height: 25px;
  
  }
  
