.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-inner {
    position: relative;
    padding: 16px;
    width: 100%;
    max-width: 1000px;
    background-color: #fff;
    border-radius: 5px;
    animation: pop-swirl linear 250ms forwards;
}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 10px;
    margin: 0px;
    font-size: 20px;
    border-radius: 50%;
    color: white;
    background-color: #1e89b4;
    cursor: pointer;
}

h1 {
    background-color: #1e89b4;
    top: 16px;
    left: 16px;
    padding: 10px;
    border-radius: 5px;
    font-size: 20px;
    color: white;
}

@keyframes pop-swirl {
    0% {
        transform: scale(0) rotate(0deg);
        z-index: 1000;
    }

    50% {
        transform: scale(0.5) rotate(0deg);
        z-index: 1000;
    }

    100% {
        transform: scale(1) rotate(0deg);
    }
}