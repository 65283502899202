.refinement{
    width: 20%;
    height: fit-content;
    margin-top: 20px;
    padding: 10px 0 10px 10px;
    border-radius: 3px;
    background-color: #EEF5F7;

    .title {
        color: #000;
    }

    .list {
        color: #00688b;

        &:hover {
            color: #19ACA2;
        }
    }

    @media screen and (max-width: 900px) {
        width: 25%;
        margin-top: 10px;
    }

    @media screen and (max-width: 769px) {
        display: none;
    }
}

.refinement-mobile{
    height: 100%;
    max-height: 250px;
    overflow: scroll;
    padding: 0px 10px 10px 20px;

    .title {
        color: #000;
    }    
}