* {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
}

.home-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.7);
}




/* .closeBtn {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 390px;
    color: gray;
    font-size: 50px;
    cursor: pointer;
    transform: translateY(-5px);
    z-index: 75;
} */